import request from '../../utils/request'

//获取职业史分页接口
export function getOrderLIst(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/occupationalDisease/pageList'
  })
}
// 危害因素列表
export function getType1List(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/hazardousFactor'
  })
}
//删除危害因素
export function delType1(id: number): Promise<any> {
  return request({
    method: 'DELETE',
    url: 'manage/hazardousFactor?id='+id,
  })
}
// 疾病列表列表
export function getType2List(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/diseaseType'
  })
}
//删除疾病列表
export function delType2(id: number): Promise<any> {
  return request({
    method: 'DELETE',
    url: 'manage/diseaseType?id='+id,
  })
}
// 问卷症状列表
export function getType3List(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/symptom'
  })
}
//删除 问卷症状
export function delType3(id: number): Promise<any> {
  return request({
    method: 'DELETE',
    url: 'manage/symptom?id='+id,
  })
}
// 防护措施 列表
export function getType4List(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/preventiveMeasure'
  })
}
//删除 防护措施
export function delType4(id: number): Promise<any> {
  return request({
    method: 'DELETE',
    url: 'manage/preventiveMeasure?id='+id,
  })
}


// 根据id获取详情页面
export function getOneDetail(id: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/occupationalDisease/detail?quesId=' + id
  })
}


